const anthologyLiveBaseUrl = "https://anthology.to";
const slinkyLiveBaseUrl = "https://slinky.to";
// const devBaseUrl = process.env.NEXT_PUBLIC_VITE_API_ROOT || process.env.VITE_API_ROOT;
const devBaseUrl = "https://localhost:5002";
const defaultBaseUrl =
  typeof window !== "undefined"
    ? window.location.host.indexOf("localhost") > -1
      ? devBaseUrl
      : window.location.host.indexOf("slinky") > -1
        ? slinkyLiveBaseUrl
        : ""
    : anthologyLiveBaseUrl;
const CDNRoot = "https://cdn.anthology.to";
const staticPath = `${CDNRoot}/static/`;
const defaultErrorImage = `${CDNRoot}/static/missing.png`;
const generalPath = `${CDNRoot}/static/general/`;
const logosPath = `${CDNRoot}/static/logos/`;
const avatarBlankDark = `${generalPath}avatar-blank-dark-mode.png`;
const avatarBlankLight = `${generalPath}avatar-blank.png`;
const flagPath = `${staticPath}flags/`;
const unknownImage = `${flagPath}circle/xx.svg`;
const slinkyIconUrl = `${logosPath}slinky-icon.png`;
const slinkyBaseUrl = "https://slinky.to/";
const slinkyDestinationFallBackImg = `${staticPath}slinky/slinky_destination_fallback.png`;
const mockImagesUrl = `${CDNRoot}/mock_images/`;
const privacyUrl = "/site/privacy-policy";

export {
  anthologyLiveBaseUrl,
  avatarBlankDark,
  avatarBlankLight,
  CDNRoot,
  defaultBaseUrl,
  defaultErrorImage,
  devBaseUrl,
  flagPath,
  generalPath,
  logosPath,
  mockImagesUrl,
  privacyUrl,
  slinkyBaseUrl,
  slinkyDestinationFallBackImg,
  slinkyIconUrl,
  staticPath,
  unknownImage,
};
